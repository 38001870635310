:root {
  // fonts
  --main-font-family: 'Roboto', sans-serif;

  // colors
  --color-white: #fff;
  --color-aquamarine: #66FFD1;
  --color-grey: #B5B4B4;
  --color-darkgrey: #3B3A41;
}
