@import 'styles/abstract/mixins';

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  // place holder while bg video is loading
  background-image: url('../../assets/images/mint-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: var(--color-white);

  @include respond(md) {
    padding: 2rem;
  }

  &::after {
    content: ' ';
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
}

.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.content {
  flex: 1 1 auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-items: center;
  margin-bottom: 4rem;
  grid-gap: 5rem;
  text-transform: uppercase;
  font-size: 3.3rem;
  z-index: 1;

  @include respond(lg) {
    font-size: 2.8rem;
  }

  @include respond(md) {
    font-size: 2rem;
    display: flex;
    justify-content: center;
  }

  @include respond(sm) {
    flex-direction: column;
  }

  @include respond(xs) {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
}

.left {
  display: flex;
  align-items: center;
  gap: 7rem;

  @include respond(xs) {
    flex-direction: column;
    gap: 2rem;
  }
}

.count {
  display: flex;
  align-items: center;
  gap: 2rem;

  button {
    height: 4rem;
    width: 4rem;
    border-radius: 10rem;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    background: none;
    border: none;
    overflow: hidden;

    img {
      width: 180%;
      height: 180%;
    }
  }

  span {
  }
}

.total {
}

.middle {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
  margin-top: 25rem;

  @include respond(md) {
    margin-top: 0;
  }

  @include respond(sm) {
    order: -1;
  }

  button {
    display: flex;
    background: none;
    border: none;
    transition: all 0.2s ease-in-out;

    img {
      max-width: 20rem;
      width: 100%;
      opacity: 0.8;

      @include respond(xs) {
        max-width: 15rem;
      }
    }

    &:last-of-type {
      img {
        opacity: 0.4;
      }
    }

    &:hover {
      img {
        opacity: 1;
      }
    }
  }
}

.right {
  span {
  }
}

.footer {
  text-align: center;
  z-index: 1;

  p {
    font-size: 2.3rem;

    @include respond(xs) {
      font-size: 1.5rem;
    }

    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

