*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font-family);
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: var(--main-font-family);
  // color: var(--color-black);
  min-height: 100vh;
}

a {
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea {
  font-family: inherit;
}

button {
  cursor: pointer;
  color: inherit;

  * {
    color: inherit;
  }
}
