@import 'styles/abstract/mixins';

.container {
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 6rem;
  // place holder while bg video is loading
  background-image: url('../../assets/images/pub-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include respond(xs) {
    padding: 4rem;
  }

  &::after {
    content: ' ';
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.1);
    z-index: 0;
  }
}

.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.link {
  padding: 1rem;
  z-index: 1;

  img {
    max-width: 55rem;
    width: 100%;
  }
}
